import * as React from 'react'
import {css} from '@emotion/core'
import {Manager} from 'feedbackwidget'
import { useCallback, useMemo, useState } from 'react'
import { Button } from './Button'
import { ErrorMessage, Field, Formik } from 'formik'
import { isBrowser } from '../utils'


/**
 <script>
 Feedbackwidget=window.Feedbackwidget||[];(function(){var uv=document.createElement('script');uv.type='text/javascript';uv.async=true;uv.id='feedbackwidgetlib';uv.src='{{jslib_url}}/dist/trigger.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(uv,s)})();
 </script>
 <script>
 Feedbackwidget.push(['set', {
  widgetId: 'b69c565e8c534e2782392fe86cd5d3f4'
}]);
 Feedbackwidget.push(['addTrigger', { position: { top: 10, left: 60 } }]);


 */


export function ContactForm(props: any) {
  const api = useMemo(() => {
    if (!isBrowser()) {
      return null;
    }
    return new Manager("b69c565e8c534e2782392fe86cd5d3f4");
  }, []);

  const [submissionResult, setSubmissionResult] = useState<any>({});

  return <div css={css`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    
    form {
      position: relative;
    }
    
    .overlay {
      position: absolute;
      left: 0;      
      top: 0;
      right: 0;
      bottom: 0;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: whitesmoke;
      text-align: center;
      
      .againButton {
        margin-top: 20px;
      }
    }
    
    .field {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      
      & label {
        color: gray;
        font-size: .8em;
      }
    }
    
    .actions {
      margin-top: 10px;
    }
    
    input, textarea {
      font-size: 20px;
      padding: .5em;          
    }
    textarea {
    }
  `}>
    <Formik
      initialValues={{
        name: '',
        contact: '',
        text: ''
      }}
      validate={values => {
        const errors: any = {};
        if (!values.text) {
          errors.text = 'Bitte schreiben Sie hier ihre Nachricht an uns.';
        }

        const email_re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;
        const phone_re = /[0-9 +/-]/i;

        if (
          !email_re.test(values.contact) && !phone_re.test(values.contact)
        ) {
          errors.contact = 'Bitte geben Sie hier eine Telefonnummer oder E-Mail Adresse an, oder beides.';
        }
        return errors;
      }}
      // @ts-ignore
      onSubmit={async (values, { setSubmitting }) => {
        setSubmissionResult({});
        try {
          await api.identify({
            name: values.name,
            email: values.contact
          });
          await api.newChat({
            message: values.text
          });
          setSubmissionResult({success: true});
        }
        catch(e) {
          setSubmissionResult({error: true});
          alert("Die Nachricht konnte nicht gesendet werden.")
          console.log(e);
        }
        finally {
          setSubmitting(false);
        }
      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          isSubmitting,
          /* and other goodies */
        }) => (
        <form onSubmit={handleSubmit}>
          {submissionResult.success ? <div className={"overlay"}>
            Ihre Nachricht wurde übermittelt. Vielen Dank! Wir werden uns so bald
            wie möglich mit Ihnen in Verbindung setzen.
            <div className={"againButton"}>
              <Button onClick={() => setSubmissionResult(null)}>Noch eine Nachricht schreiben</Button>
            </div>
          </div> : null}
          <div className={"field"}>
            <label>Ihr Name</label>
            <Field type="name" name="name" placeholder={"Vor- und Nachname"} />
            <ErrorMessage name="name" component={DisplayError} />
          </div>

          <div className={"field"}>
            <label>Wie sollen wir Ihnen antworten?</label>
            <Field type="contact" name="contact"  placeholder={"E-Mail Adresse, Telefonnummer"} />
            <ErrorMessage name="contact" component={DisplayError} />
          </div>

          <div className={"field"}>
            <label>Ihr Anliegen</label>
            <Field type="text" name="text" as="textarea" style={{height: '400px'}} />
            <ErrorMessage name="text" component={DisplayError} />
          </div>

          <div className={"actions"}>
            <Button onClick={submitForm}>
              Nachricht senden
            </Button>
          </div>
        </form>
      )}
    </Formik>
  </div>
}


export function DisplayError(props: {
  children: any
}) {
  return <div css={css`
    color: #f44336;
  `}>
    {props.children}
  </div>
}